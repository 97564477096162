import React from 'react';
import { GitLabProjectSelectorValidator } from './scaffolder/components/GitlabProcessSelector';
import {RdsProjectSelector} from './scaffolder/components/RdsSelector';
import {LoadBalancerSelector} from './scaffolder/components/LoadBalancerSelector';
import { AppCatalogPage } from '@internal/backstage-plugin-onfly-plugins';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import {NewRelicPage} from '@backstage-community/plugin-newrelic';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { HomePage } from './components/home/HomePage';
import { OPAHomePage, customerTheme, awsTheme } from '@internal/backstage-plugin-onfly-plugins';
import { Route } from 'react-router-dom';
import {EntityGitlabContent} from '@internal/backstage-plugin-onfly-gitlab-plugins';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import { AutoLogout } from '@backstage/core-components';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { gitlabAuthApiRef } from '@backstage/core-plugin-api';
import {CpuMemorySelectorValidator} from "./scaffolder/components/CpuMemorySelector";
import {ScalingSelectorValidator} from "./scaffolder/components/ScalingSelector";
import {IacJobsPage} from "@internal/backstage-plugin-onfly-plugins/src/plugin";
import {EnvVariablesSelectorValidator} from "./scaffolder/components/EnvVariablesSelector";
import {GuardianPage} from "@internal/backstage-plugin-onfly-plugins/src/plugin";




const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
      bind(scaffolderPlugin.externalRoutes, {
          registerComponent: catalogImportPlugin.routes.importPage,
          viewTechDoc: techdocsPlugin.routes.docRoot,
      });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'customerTheme',
      title: 'CUSTOMER',
      variant: 'light',
      Provider: ({ children }) => (
        <ThemeProvider theme={customerTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
    {
      id: 'light',
      title: 'Light',
      variant: 'light',
      Provider: ({ children }) => (
        <ThemeProvider theme={customerTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
    {
      id: 'dark',
      title: 'Dark',
      variant: 'dark',
      Provider: ({ children }) => (
        <ThemeProvider theme={customerTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
    {
      id: 'orange',
      title: 'Orange',
      variant: 'dark',
      Provider: ({ children }) => (
        <ThemeProvider theme={awsTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
    components: {
        SignInPage: props => (
            <SignInPage
                {...props}
                auto
                provider={{
                    id: 'gitlab-auth-provider',
                    title: 'GitLab',
                    message: 'Sign in using GitLab',
                    apiRef: gitlabAuthApiRef,
                }}
            />
        ),
    },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<OPAHomePage />}>
      <HomePage />
    </Route>
    <Route path="/home" element={ <OPAHomePage /> } />
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route path="/gitlab" element={<EntityGitlabContent />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={
    <ScaffolderPage
      groups={[
        {
          title: "Hyperf Project",
          filter: entity =>
          entity?.metadata?.tags?.includes('hyperf') ?? false,
        },
        {
          title: "AWS Resources",
          filter: entity =>
          entity?.metadata?.tags?.includes('aws-resource') ?? false,
        },
        {
          title: "Providers",
          filter: entity =>
          entity?.metadata?.tags?.includes('environment-provider') ?? false,
        },
        {
          title: "Environments",
          filter: entity =>
          entity?.metadata?.tags?.includes('environment') ?? false,
        },
    ]}
/>}>

    <ScaffolderFieldExtensions>
        <GitLabProjectSelectorValidator />
        <EnvVariablesSelectorValidator />
        <RdsProjectSelector />
        < LoadBalancerSelector/>
        <CpuMemorySelectorValidator />
        <ScalingSelectorValidator />
    </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />}/>
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/newrelic" element={<NewRelicPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/aws-apps-search-page" element={<CatalogIndexPage />}>
     <AppCatalogPage kind="all" />
   </Route>
   <Route path="/aws-apps-search-page/environments" element={<CatalogIndexPage />}>
     <AppCatalogPage kind="awsenvironment" />
   </Route>
   <Route path="/aws-apps-search-page/providers" element={<CatalogIndexPage />}>
     <AppCatalogPage kind="awsenvironmentprovider" />
   </Route>
      <Route path="/aws-apps-search-page/envs" element={<CatalogIndexPage />}>
     <AppCatalogPage kind="envvariablesentity" />
   </Route>
   <Route path="/aws-apps-search-page/apps" element={<CatalogIndexPage />}>
     <AppCatalogPage kind="component" />
   </Route>
   <Route path="/aws-apps-search-page/resources" element={<CatalogIndexPage />}>
     <AppCatalogPage kind="resource" />
   </Route>
      <Route path="/aws-apps-search-page/iacjobs" element={<IacJobsPage />}/>
      <Route path="/aws-apps-search-page/guardian" element={<GuardianPage />}/>
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <AutoLogout
    idleTimeoutMinutes={60}
    useWorkerTimers={false}
    logoutIfDisconnected={false}  />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
