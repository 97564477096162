import React, {useEffect, useState} from 'react';
import {FieldExtensionComponentProps} from "@backstage/plugin-scaffolder-react";
import { Box } from '@material-ui/core';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";


type CpuMem = {
    cpu: number;
    memory: number;
}

const options : any = {
    256: ['512MiB', '1GB', '2GB'],
    512: ['1GB', '2GB', '3GB', '4GB'],
    1024: ['2GB', '3GB', '4GB', '5GB', '6GB', '7GB', '8GB'],
    2048: Array.from({ length: 13 }, (_, i) => `${4 + i}GB`), // 4GB to 16GB
    4096: Array.from({ length: 23 }, (_, i) => `${8 + i}GB`), // 8GB to 30GB
    8192: Array.from({ length: 12 }, (_, i) => `${16 + i * 4}GB`), // 16GB to 60GB in 4GB increments
    16384: Array.from({ length: 12 }, (_, i) => `${32 + i * 8}GB`) // 32GB to 120GB in 8GB increments
};


export const CpuMemorySelector = ({ onChange }: FieldExtensionComponentProps<CpuMem>) => {
    const [selectedCpu, setSelectedCpu] = useState<string>('');
    const [selectedMemory, setSelectedMemory] = useState<string>('');

    const convertToInteger = (cpu: string, memory: string) => {
        const cpuInt = parseInt(cpu, 10);
        const memoryInt = parseInt(memory.replace('GB', '').replace('MiB', ''), 10) * (memory.includes('GB') ? 1024 : 1);
        return { cpu: cpuInt, memory: memoryInt };
    };

    useEffect(() => {
        if (selectedCpu && selectedMemory) {
            const convertedValues: CpuMem = convertToInteger(selectedCpu, selectedMemory);
            onChange(convertedValues);
        }
    }, [selectedCpu, selectedMemory, onChange]);

    const handleCpuChange = (event: any) => {
        setSelectedCpu(event.target.value);
        setSelectedMemory(''); // Reset the memory selector when CPU changes
    };

    const handleMemoryChange = (event: any) => {
        setSelectedMemory(event.target.value);
    };

    return (
        <Box>
            <FormControl fullWidth margin="normal">
                <InputLabel id="cpuSelectorLabel">CPU</InputLabel>
                <Select
                    labelId="cpuSelectorLabel"
                    id="cpuSelector"
                    value={selectedCpu}
                    label="CPU"
                    onChange={handleCpuChange}
                >
                    <MenuItem value="">
                        <em>Select CPU</em>
                    </MenuItem>
                    {Object.keys(options).map((cpu) => (
                        <MenuItem key={cpu} value={cpu}>{`${cpu} (.25 vCPU)`}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {selectedCpu && (
                <FormControl fullWidth margin="normal">
                    <InputLabel id="memorySelectorLabel">Memory</InputLabel>
                    <Select
                        labelId="memorySelectorLabel"
                        id="memorySelector"
                        value={selectedMemory}
                        label="Memory"
                        onChange={handleMemoryChange}
                    >
                        <MenuItem value="">
                            <em>Select Memory</em>
                        </MenuItem>
                        {options[selectedCpu].map((memory: any) => (
                            <MenuItem key={memory} value={memory}>{memory}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </Box>
    );
};
