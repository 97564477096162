import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
    RdsConfigurator,
} from './RdsProjectSelector';

export const RdsProjectSelector = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'RdsProjectSelector',
    component: RdsConfigurator,
  }))