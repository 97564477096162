import {
  createPlugin,
  identityApiRef,
  createComponentExtension,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import {
  configApiRef,
  createApiFactory,
  createRouteRef,
  discoveryApiRef,
  gitlabAuthApiRef,
} from '@backstage/core-plugin-api';
import { GitlabCIApiRef, GitlabCIClient } from '../api';

import { rootRouteRef } from './routes';

export const onflyGitlabPluginsPlugin = createPlugin({
  id: 'onfly-gitlab-plugins',
  apis: [
    createApiFactory({
        api: GitlabCIApiRef,
        deps: {
            configApi: configApiRef,
            discoveryApi: discoveryApiRef,
            identityApi: identityApiRef,
            gitlabAuthApi: gitlabAuthApiRef,
        },
        factory: ({
            configApi,
            discoveryApi,
            identityApi,
            gitlabAuthApi,
        }) =>
            GitlabCIClient.setupAPI({
                discoveryApi,
                identityApi,
                codeOwnersPath: configApi.getOptionalString(
                    'gitlab.defaultCodeOwnersPath'
                ),
                readmePath: configApi.getOptionalString(
                    'gitlab.defaultReadmePath'
                ),
                gitlabAuthApi,
                useOAuth: configApi.getOptionalBoolean('gitlab.useOAuth'),
            }),
    }),
],
});

export const EntityGitlabContent = onflyGitlabPluginsPlugin.provide(
  createRoutableExtension({
      name: 'EntityGitlabContent',
      component: () =>
          import('../components/GitlabCI').then((m) => m.GitlabCI),
      mountPoint: rootRouteRef,
  })
);

export const EntityGitlabLanguageCard = onflyGitlabPluginsPlugin.provide(
  createComponentExtension({
      name: 'EntityGitlabLanguageCard',
      component: {
          lazy: () =>
              import('../components/widgets/LanguagesCard').then(
                  (m) => m.LanguagesCard
              ),
      },
  })
);

export const EntityGitlabPeopleCard = onflyGitlabPluginsPlugin.provide(
  createComponentExtension({
      name: 'EntityGitlabPeopleCard',
      component: {
          lazy: () =>
              import('../components/widgets/PeopleCard').then((m) => m.PeopleCard),
      },
  })
);

export const EntityGitlabMergeRequestsTable = onflyGitlabPluginsPlugin.provide(
  createComponentExtension({
      name: 'EntityGitlabMergeRequestsTable',
      component: {
          lazy: () =>
              import('../components/widgets/MergeRequestsTable').then(
                  (m) => m.MergeRequestsTable
              ),
      },
  })
);

export const EntityGitlabMergeRequestStatsCard = onflyGitlabPluginsPlugin.provide(
  createComponentExtension({
      name: 'EntityGitlabMergeRequestStatsCard',
      component: {
          lazy: () =>
              import('../components/widgets/MergeRequestStats').then(
                  (m) => m.MergeRequestStats
              ),
      },
  })
);

export const EntityGitlabPipelinesTable = onflyGitlabPluginsPlugin.provide(
  createComponentExtension({
      name: 'EntityGitlabPipelinesTable',
      component: {
          lazy: () =>
              import('../components/widgets/PipelinesTable').then(
                  (m) => m.PipelinesTable
              ),
      },
  })
);

export const EntityGitlabReleasesCard = onflyGitlabPluginsPlugin.provide(
  createComponentExtension({
      name: 'EntityGitlabReleasesCard',
      component: {
          lazy: () =>
              import('../components/widgets/ReleasesCard').then(
                  (m) => m.ReleasesCard
              ),
      },
  })
);

export const EntityGitlabCoverageCard = onflyGitlabPluginsPlugin.provide(
  createComponentExtension({
      name: 'EntityGitlabCoverageCard',
      component: {
          lazy: () =>
              import('../components/widgets/CoverageCard').then(
                  (m) => m.CoverageCard
              ),
      },
  })
);

export const EntityGitlabIssuesTable = onflyGitlabPluginsPlugin.provide(
  createComponentExtension({
      name: 'EntityGitlabIssuesTable',
      component: {
          lazy: () =>
              import('../components/widgets/IssuesTable').then((m) => m.IssuesTable),
      },
  })
);

export const EntityGitlabReadmeCard = onflyGitlabPluginsPlugin.provide(
  createComponentExtension({
      name: 'EntityGitlabReadmeCard',
      component: {
          lazy: () =>
              import('../components/widgets/ReadmeCard').then((m) => m.ReadmeCard),
      },
  })
);
