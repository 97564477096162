import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  GitLabProjectSelector,
} from './GitLabProjectSelector';

export const GitLabProjectSelectorValidator = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'GitLabProjectSelector',
    component: GitLabProjectSelector,
  }))