import { ServicesResponse } from '@aws/amazon-ecs-plugin-for-backstage-common';
import { createApiRef } from '@backstage/core-plugin-api';
import type { CompoundEntityRef } from '@backstage/catalog-model';

export const amazonEcsApiRef = createApiRef<AmazonEcsApi>({
  id: 'onfly-ecs',
});

export interface AmazonEcsApi {
  service_arn: string;
  getServicesByEntity({
    entity,
  }: {
    entity: CompoundEntityRef;
    service_arn: string;
  }): Promise<ServicesResponse>;
}