import {
  createPlugin,
  createComponentExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { amazonEcsApiRef } from '../components/CustomEcsComponent/components/api';
import { AmazonEcsApiClient } from '../components/CustomEcsComponent/components/api';
import { configApiRef, fetchApiRef, discoveryApiRef } from '@backstage/core-plugin-api';
import { createApiFactory } from '@backstage/core-plugin-api';
import { OPAApiClient } from '@internal/backstage-plugin-onfly-plugins';
import { opaApiRef } from '@internal/backstage-plugin-onfly-plugins';
import { Entity } from '@backstage/catalog-model';

export const isOPAAppAvailable = (entity: Entity) => entity?.spec?.type === 'aws-app';
export const isAnnotationsAvailable = (entity: Entity) => entity?.metadata?.annotations;
export const isLabelsAvailable = (entity: Entity) => entity?.metadata?.labels;


export const onflyPluginsPlugin = createPlugin({
  id: 'onfly-plugins',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: amazonEcsApiRef,
      deps: { configApi: configApiRef, fetchApi: fetchApiRef, discoveryApi: discoveryApiRef,  },
      factory: ({ fetchApi, discoveryApi }) =>
        new AmazonEcsApiClient({ discoveryApi, fetchApi, service_arn: '' }),
    }),
    createApiFactory({
      api: opaApiRef,
      deps: { configApi: configApiRef, fetchApi: fetchApiRef },
      factory: ({ configApi, fetchApi }) => new OPAApiClient({ configApi, fetchApi }),
    }),
  ],

});

export const EntityLabelTable = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'EntityLabelTable',
    component: {
      lazy: () => import('../components/LabelTable/LabelTable').then(m => m.LabelWidget),
    },
  }),
);

export const EntityAuditTable = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'EntityAuditTable',
    component: {
      lazy: () => import('../components/AuditTable/AuditTable').then(m => m.AuditWidget),
    },
  }),
);

export const EntityEnvironmentSelector = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'EnvironmentSelector',
    component: {
      lazy: () => import('../components/EnvironmentSelector/EnvironmentSelector').then(m => m.EnvironmentSelectorWidget),
    },
  }),
);

export const EntityAnnotationTypeTable = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'EntityAnnotationTypeTable',
    component: {
      lazy: () => import('../components/AnnotationTypeTable/AnnotationTypeTable').then(m => m.AnnotationWidget),
    },
  }),
);

export const EntityAppStateCard = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'AppStateCard',
    component: {
      lazy: () => import('../components/AppStateCard/AppStateCard').then(m => m.AppStateCard),
    },
  }),
);

export const EntityAppRdsCard = onflyPluginsPlugin.provide(
    createComponentExtension({
        name: 'AppRdsCard',
        component: {
            lazy: () => import('../components/RdsCard/AppRdsCard').then(m => m.OnflyRdsCard),
        }
    })
)

export const EntityK8sAppStateCard = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'K8sAppStateCard',
    component: {
      lazy: () => import('../components/K8sAppStateCard/K8sAppStateCard').then(m => m.K8sAppStateCard),
    },
  }),
);

export const EntityAppStateCardCloudFormation = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'AppStateCardCloudFormation',
    component: {
      lazy: () => import('../components/AppStateCardCloudFormation/AppStateCardCloudFormation').then(m => m.AppStateCard),
    },
  }),
);

export const EntityGeneralInfoCard = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'GeneralInfoCard',
    component: {
      lazy: () => import('../components/GeneralInfoCard/GeneralInfoCard').then(m => m.GeneralInfoCard),
    },
  }),
);

export const EntityAppPromoCard = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'AppPromoCard',
    component: {
      lazy: () => import('../components/AppPromoCard/AppPromoCard').then(m => m.AppPromoWidget),
    },
  }),
);

export const EntityAppLinksCard = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'AppLinksCard',
    component: {
      lazy: () => import('../components/AppLinksCard/AppLinksCard').then(m => m.AppLinksCard),
    },
  }),
);

export const AppCatalogPage = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'AppCatalogPage',
    component: {
      lazy: () => import('../components/AppCatalogPage/AppCatalogPage').then(m => m.AppCatalogPage),
    },
  }),
);

export const EntityCloudwatchLogsTable = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'EntityCloudwatchLogsTable',
    component: {
      lazy: () => import('../components/CloudwatchLogsTable/CloudwatchLogsTable').then(m => m.CloudwatchLogsWidget),
    },
  }),
);

export const EntityInfrastructureInfoCard = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'InfrastructureInfoCard',
    component: {
      lazy: () => import('../components/InfrastructureCard/InfrastructureCard').then(m => m.InfrastructureCard),
    },
  }),
);


export const EntityProviderInfoCard = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'ProviderInfoCard',
    component: {
      lazy: () => import('../components/ProviderInfoCard/ProviderInfoCard').then(m => m.ProviderInfoCard),
    },
  }),
);

export const EntityEnvironmentInfoCard = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'EnvironmentInfoCard',
    component: {
      lazy: () => import('../components/EnvironmentInfoCard/EnvironmentInfoCard').then(m => m.EnvironmentInfoCard),
    },
  }),
);

export const EntityAppConfigCard = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'AppConfigCard',
    component: {
      lazy: () => import('../components/AppConfigCard/AppConfigCard').then(m => m.AppConfigCard),
    },
  }),
);

export const EntityDeleteAppCard = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'DeleteAppCard',
    component: {
      lazy: () => import('../components/DeleteComponentCard/DeleteComponentCard').then(m => m.DeleteComponentCard),
    },
  }),
);

export const EntityDeleteProviderCard = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'DeleteProviderCard',
    component: {
      lazy: () => import('../components/DeleteProviderCard/DeleteProviderCard').then(m => m.DeleteProviderCard),
    },
  }),
);

export const EntityDeleteEnvironmentCard = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'DeleteEnvironmentCard',
    component: {
      lazy: () => import('../components/DeleteEnvironmentCard/DeleteEnvironmentCard').then(m => m.DeleteEnvironmentCard),
    },
  }),
);

export const EntityResourceBindingCard = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'ResourceBindingCard',
    component: {
      lazy: () => import('../components/ResourceBindingCard/ResourceBinding').then(m => m.ResourceBindingCardWidget),
    },
  }),
);

export const EntityAwsEnvironmentProviderSelectorCard = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'AwsEnvironmentProviderSelectorCard',
    component: {
      lazy: () => import('../components/AwsEnvironmentProviderCard/AwsEnvironmentProviderCard').then(m => m.AwsEnvironmentProviderCardWidget),
    },
  }),
);

export const AwsAppPage = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'AwsAppPage',
    component: {
      lazy: () => import('../pages/AwsAppPage/AwsAppPage').then(m => m.AwsAppPage),
    },
  }),
);

export const AwsComponentPage = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'AwsComponentPage',
    component: {
      lazy: () => import('../pages/AwsComponentPage/AwsComponentPage').then(m => m.AwsComponentPage),
    },
  }),
);

export const AwsEnvironmentPage = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'AwsEnvironmentPage',
    component: {
      lazy: () => import('../pages/AwsEnvironmentPage/AwsEnvironmentPage').then(m => m.AwsEnvironmentPage),
    },
  }),
);

export const EnvProjectInfoCard = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'EnvProjectInfoCard',
    component: {
      lazy: () => import('../components/EnvProjectInfoCard/EnvProjectInfoCard').then(m => m.EnvironmentInfoCard),
    },
  }),
);

export const AwsEnvironmentProviderPage = onflyPluginsPlugin.provide(
  createComponentExtension({
    name: 'AwsEnvironmentProviderPage',
    component: {
      lazy: () => import('../pages/AwsEnvironmentProviderPage/AwsEnvironmentProviderPage').then(m => m.AwsEnvironmentProviderPage),
    },
  }),
);

export const IacJobsPage = onflyPluginsPlugin.provide(
    createComponentExtension({
        name: 'IacJobsExecutor',
        component: {
            lazy: () => import('../components/IacJobs/IacJobs').then(m => m.IacJobExecutor),
        },
    }),
);

export const EnvironmentVariablesPage = onflyPluginsPlugin.provide(
    createComponentExtension({
        name: 'EnvironmentVariablesPage',
        component: {
            lazy: () => import('../pages/EnvironmentVariablesPage/EnvironmentVariablesPage').then(m => m.EnvironmentVariablesPage),
        }
    })
)

export const CustomEntityAmazonEcsServicesContent = onflyPluginsPlugin.provide(
  createComponentExtension({
      name: 'EcsServices',
      component: {
          lazy: () => import('../components/CustomEcsComponent/components/index').then(m => m.Router ),
      },
  }),
);

export const GuardianPage = onflyPluginsPlugin.provide(
    createComponentExtension( {
        name: 'GuardianPage',
        component: {
            lazy: () => import('../components/TheGuardian/GuardianPage').then(m => m.GuardianPage)
        }
    })
)

export const DefaultVariablesCard = onflyPluginsPlugin.provide(
    createComponentExtension({
        name: 'DefaultVariablesPage',
        component: {
            lazy: () => import('../components/DefaultVariablesCard/DefaultVariablesCard').then(m => m.DefaultVariablesPage),
        },
    }),
);