import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
    CpuMemorySelector,
} from './CpuMemorySelector';

export const CpuMemorySelectorValidator = scaffolderPlugin.provide(
    createScaffolderFieldExtension({
        name: 'CpuMemorySelector',
        component: CpuMemorySelector,
    }))