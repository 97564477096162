import React, { useEffect, useState } from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { gitlabAuthApiRef } from '@backstage/core-plugin-api';
import { useApi } from '@backstage/core-plugin-api';



type Project = {
    name: string;
    full_path: string;
  }
  
export const GitLabProjectSelector = ({
  formData,
  onChange,
}: FieldExtensionComponentProps<Project>) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const gitlabAuthApi = useApi(gitlabAuthApiRef);


  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const gitAcessToken = await gitlabAuthApi.getAccessToken(['read_api', 'read_repository']);
        
        const response = await fetch('/api/proxy/gitlab/api/groups?min_access_level=40&membership=true',
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${gitAcessToken}`
            }
          }
        );
        const formattedResponse = await response.json();
        const formattedProjects: Project[] = [];

        formattedResponse.map( (project: any) => formattedProjects.push({name: project.name,
          full_path: project.full_path}));

        setProjects(formattedProjects);
      } catch (e) {
        console.error('Failed to fetch projects', e);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [gitlabAuthApi]);

    return (
    <FormControl margin="normal" fullWidth>
      <InputLabel id="gitlab-projects-label">GitLab Projects</InputLabel>
      <Select
        labelId="gitlab-projects-label"
        value={formData ? JSON.stringify(formData) : ''}
        onChange={e => {
          const selectedProject = projects.find(project => JSON.stringify(project) === e.target.value);
          if (selectedProject) {
            onChange(selectedProject);
          }
        }}
        disabled={loading}
      >
        {projects.map((project) => (
          <MenuItem key={project.name} value={JSON.stringify(project)}>
            {project.full_path}
          </MenuItem>
        ))}
      </Select>
      {loading && <FormHelperText>Loading projects...</FormHelperText>}
    </FormControl>
  );
};