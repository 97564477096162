import React, {useEffect, useState} from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box, SelectChangeEvent } from '@mui/material';
import {FieldExtensionComponentProps} from "@backstage/plugin-scaffolder-react";

export interface LoadBalancerConfig {
  createLoadBalancer: boolean
  idleTimeout?: string
  type?: string;
}

export const LoadBalancerConfigurator = ({ onChange }: FieldExtensionComponentProps<LoadBalancerConfig> ) => {
  const [createLoadBalancer, setCreateLoadBalancer] = useState('');
  const [timeout, setTimeout] = useState('');

  const [loadBalancer, setLoadBalancer] = useState({});

  const handleLoadBalancerChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;

    if (selectedValue === 'Yes') {
      setCreateLoadBalancer(selectedValue);
      setLoadBalancer({ idleTimeout: timeout });
    } else {
      setCreateLoadBalancer(selectedValue);
      setLoadBalancer({});
    }
  };

  const handleTimeoutChange = (event: SelectChangeEvent) => {
    const selectedTimeout = event.target.value;
    setLoadBalancer({ ...loadBalancer, idleTimeout: selectedTimeout });
    setTimeout(selectedTimeout);
  };

  useEffect(() => {
    if(createLoadBalancer === 'Yes') {
      onChange({type: 'ApplicationLoadBalancer',  createLoadBalancer: true, idleTimeout: timeout});
    }else {
      onChange({createLoadBalancer: false});
    }
  }, [createLoadBalancer, loadBalancer, onChange, timeout]);

  return (
    <Box>
      <FormControl fullWidth margin="normal">
        <InputLabel id="create-loadbalancer-select-label">Create Application LoadBalancer?</InputLabel>
        <Select
          labelId="create-loadbalancer-select-label"
          id="create-loadbalancer-select"
          value={createLoadBalancer}
          label="Create Application LoadBalancer?"
          onChange={handleLoadBalancerChange}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      </FormControl>
      {createLoadBalancer === 'Yes' && (
        <FormControl fullWidth margin="normal">
          <InputLabel id="loadbalancer-timeout-select-label">Timeout (seconds)</InputLabel>
          <Select
            labelId="loadbalancer-timeout-select-label"
            id="loadbalancer-timeout-select"
            value={timeout}
            label="Timeout (seconds)"
            onChange={handleTimeoutChange}
          >
            <MenuItem value={60}>60</MenuItem>
            <MenuItem value={120}>120</MenuItem>
            <MenuItem value={240}>240</MenuItem>
            <MenuItem value={480}>480</MenuItem>
          </Select>
        </FormControl>
      )}
    </Box>
  );
};