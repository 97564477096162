import React, { PropsWithChildren } from 'react';
import { CustomerLogoFullLight, CustomerLogoIcon } from '@internal/backstage-plugin-onfly-plugins';
import { makeStyles } from '@material-ui/core';
import CloudIcon from '@material-ui/icons/Cloud';
import HomeIcon from '@material-ui/icons/Home';
import SyncAltSharp from '@material-ui/icons/SyncAltSharp';
import NetworkCheck from '@material-ui/icons/NetworkCheck';
import AccountTree from '@material-ui/icons/AccountTree';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import {
  SidebarSubmenu,
  SidebarSubmenuItem,
} from '@backstage/core-components';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { useApi, useApp, appThemeApiRef } from '@backstage/core-plugin-api';


function getLogo(themeId: string) {
     switch (themeId) {
       case 'customerTheme':
         return[<CustomerLogoFullLight />, <CustomerLogoIcon />];
       default:
         return [<CustomerLogoFullLight />, <CustomerLogoIcon />];
     }
   }

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const appThemeApi = useApi(appThemeApiRef);
  const themeId = appThemeApi.getActiveThemeId();
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();
  const [fullLogo, iconLogo] = getLogo(themeId ?? '');

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
      {isOpen ? fullLogo : iconLogo}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="/" text="Home" />
        <SidebarDivider />
       <SidebarGroup label="AWS" icon={<MenuIcon />}>
         <SidebarItem icon={CloudIcon} text="AWS">
           <SidebarSubmenu title="AWS Catalog">
             <SidebarSubmenuItem
               title="Environments"
              to="aws-apps-search-page/environments?filters[kind]=awsenvironment"
              icon={useApp().getSystemIcon('kind:domain')}
            />
            <SidebarSubmenuItem
              title="Providers"
              to="aws-apps-search-page/providers?filters[kind]=awsenvironmentprovider"
             icon={useApp().getSystemIcon('kind:system')}
            />
            <SidebarSubmenuItem
              title="Apps"
               to="aws-apps-search-page/apps?filters[kind]=component"
               icon={useApp().getSystemIcon('kind:component')}
             />
             <SidebarSubmenuItem
               title="Resources"
               to="aws-apps-search-page/resources?filters[kind]=resource"
               icon={useApp().getSystemIcon('kind:resource')}
             />
               <SidebarSubmenuItem
               title="IAC Jobs"
               to="aws-apps-search-page/iacjobs"
               icon={SyncAltSharp} subtitle="Run Infra Pipelines"
             />
               <SidebarSubmenuItem
               title="Project Envs"
               to="aws-apps-search-page/envs"
               icon={AccountTree} subtitle="Set your project required envs"
             />
               <SidebarSubmenuItem
               title="The Guardian"
               to="aws-apps-search-page/guardian"
               icon={NetworkCheck} subtitle="Monitore and disable unused resources"
             />
           </SidebarSubmenu>
         </SidebarItem>
       </SidebarGroup>
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarScrollWrapper>
          <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
        </SidebarScrollWrapper>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
