import React, {useEffect, useState} from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField, Box, SelectChangeEvent } from '@mui/material';
import {FieldExtensionComponentProps} from "@backstage/plugin-scaffolder-react";
import {handleNumberChange} from "../common";

type RDS = {
  createRds: boolean
  min?: number,
  max?: number
  type?: string
}

export const RdsConfigurator = ({ onChange }: FieldExtensionComponentProps<RDS>) => {
  const [createRds, setCreateRds] = useState('');
  const [selectedMin, setSelectedMin] = useState(0);
  const [selectedMax, setSelectedMax] = useState(0);

  const handleRdsChange = (event: SelectChangeEvent<string>) => {
    setCreateRds(event.target.value as string);
  };

  const handleMinChange = (event: any) => {
    setSelectedMin( handleNumberChange(event.target.value));
  };

  const handleMaxChange = (event: any) => {
    setSelectedMax(handleNumberChange(event.target.value));
  };

  useEffect(() => {
    if (createRds === 'yes') {
      const result: any = {type: 'RdsInstance',
      scaling: {
        min: selectedMin, max: selectedMax
      },
        createRds: true}
      onChange(result);
    }else{
      onChange({createRds: false});
    }
  }, [selectedMin, selectedMax, onChange, createRds]);

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (target.value === '0') {
      target.value = '';
    }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="create-rds-select-label">Create Service Rds Database?</InputLabel>
        <Select
          labelId="create-rds-select-label"
          id="create-rds-select"
          value={createRds}
          label="Create RDS Database Mysql?"
          onChange={handleRdsChange}
        >
          <MenuItem value={'yes'}>Yes</MenuItem>
          <MenuItem value={'no'}>No</MenuItem>
        </Select>
      </FormControl>
      {createRds === 'yes' && (
        <>
          <TextField
            label="Min Scaling"
            value={selectedMin}
            onChange={handleMinChange}
            onClick={handleClick}
            fullWidth
            margin="normal"
            type="number"
          />
          <TextField
            label="Max Scaling"
            value={selectedMax}
            onChange={handleMaxChange}
            onClick={handleClick}
            fullWidth
            margin="normal"
            type="number"
          />
        </>
      )}
    </Box>
  );
};