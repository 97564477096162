
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';

import { ServicesResponse } from '@aws/amazon-ecs-plugin-for-backstage-common';
import { AwsApiClient } from '@aws/aws-core-plugin-for-backstage-react';
import type { CompoundEntityRef } from '../types';
import { AmazonEcsApi } from './AmazonEcsApi';

export class AmazonEcsApiClient extends AwsApiClient implements AmazonEcsApi {
  public service_arn: string;
  public constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
    service_arn: string;
  }) {
    super({
      backendName: 'onfly-ecs',
      ...options,
    });

    this.service_arn = options.service_arn;
  }
  public setServiceArn(service_arn: string): void {
    this.service_arn = service_arn;
  }
  async getServicesByEntity({
    entity,
    service_arn,
  }: {
    entity: CompoundEntityRef;
    service_arn: string;
  }): Promise<ServicesResponse> {
    const urlSegment = `v1/entity/${encodeURIComponent(
      entity.namespace,
    )}/${encodeURIComponent(entity.kind)}/${encodeURIComponent(
      entity.name,
    )}/services/?service_arn=${encodeURIComponent(service_arn)}`;


    const services = await this.get<ServicesResponse>(urlSegment);

    return services;
  }
}
