import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
    LoadBalancerConfigurator,
} from './LoadbalancerProjectSelector';

export const LoadBalancerSelector = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'LoadBalancerSelector',
    component: LoadBalancerConfigurator,
  }))