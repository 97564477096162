import { FieldExtensionComponentProps } from "@backstage/plugin-scaffolder-react";
import React, { useEffect, useState } from "react";
import { useApi } from "@backstage/core-plugin-api";
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from "@material-ui/core/FormHelperText";
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TextField from "@mui/material/TextField"
import Cancel from "@material-ui/icons/Cancel"
import CheckCircle from "@material-ui/icons/CheckCircle"
import { green, red } from "@material-ui/core/colors";

type Env = {
    name: string,
    kind: string,
    variables: any[]
}

export const EnvVariablesSelector = ({
                                         onChange,
                                     }: FieldExtensionComponentProps<Env[]>) => {
    const [envs, setEnvs] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const catalogApi = useApi(catalogApiRef);
    const [selectedEnv, setSelectedEnv] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const getEnvs = async () => {
            const response = await catalogApi.getEntities({
                filter: { 'kind': "envvariablesentity" },
                fields: ['metadata']
            });
            setEnvs(response.items);
            setLoading(false);
        };

        getEnvs();
    }, [catalogApi]);

    const validateVariables = (variables: any[]) => {
        const invalidVariable = variables.find(
            (variable: any) => variable.required && (!variable.value || !variable.key)
        );
        if (invalidVariable) {
            setError(`Please fill in all required fields.`);
            // @ts-ignore
            onChange('invalid value');
            return false;
        }
        setError(null);
        onChange(variables);
        return true;
    };

    const handleVariableChange = (index: number, key: string, value: any) => {
        const updatedVariables = [...selectedEnv.metadata.variables];
        updatedVariables[index][key] = value;
        setSelectedEnv((prev: any) => ({
            ...prev,
            metadata: { ...prev.metadata, variables: updatedVariables }
        }));

        validateVariables(updatedVariables);
    };

    return (
        <FormControl margin="normal" fullWidth>
            <InputLabel id="env-vars-label">Environment Variables Examples</InputLabel>
            <Select
                labelId="env-vars-label"
                value={selectedEnv ? selectedEnv.metadata.name : ''}
                onChange={e => {
                    const currentEnv = envs.find((env: any) => env.metadata.name === e.target.value);
                    if (currentEnv) {
                        setSelectedEnv(currentEnv);
                        validateVariables(currentEnv.metadata.variables);
                    }
                }}
                disabled={loading}
            >
                {envs.map((env: any) => (
                    <MenuItem key={env.metadata.name} value={env.metadata.name}>
                        {env.metadata.name}
                    </MenuItem>
                ))}
            </Select>

            {loading && <FormHelperText>Loading envs...</FormHelperText>}
            {selectedEnv && (
                <>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Key</TableCell>
                                <TableCell>Example Value</TableCell>
                                <TableCell>Required</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedEnv.metadata.variables.map((variable: any, index: number) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <TextField
                                            value={variable.key || ''}
                                            onChange={(e) => handleVariableChange(index, 'key', e.target.value)}
                                            disabled
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            error={variable.required && !variable.value}
                                            helperText={variable.required && !variable.value ? 'Required' : ''}
                                            value={variable.value || ''}
                                            onChange={(e) => handleVariableChange(index, 'value', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {variable.required ? (
                                            <CheckCircle style={{ color: green[500] }} />
                                        ) : (
                                            <Cancel style={{ color: red[500] }} />
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    {error && <FormHelperText error>{error}</FormHelperText>}
                </>
            )}
        </FormControl>
    );
};
