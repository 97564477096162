import React, {useEffect, useState} from 'react';
import {Box, InputLabel, TextField} from '@mui/material';
import {FieldExtensionComponentProps} from "@backstage/plugin-scaffolder-react";
import {handleNumberChange} from "../common";
import {FormControl} from "@material-ui/core";

export interface ScalingConfig {
    minCapacity: number,
    maxCapacity: number,
    desiredCount: number
}

export const ScalingSelector = ({ onChange }: FieldExtensionComponentProps<ScalingConfig> ) => {
    const [selectedMin, setSelectedMin] = useState(0);
    const [selectedMax, setSelectedMax] = useState(0);
    const [desiredCount, setDesiredCount] = useState(0);

    const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedMin(handleNumberChange(event.target.value));
    };

    const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedMax(handleNumberChange(event.target.value));
    };

    const handleDesiredCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDesiredCount(handleNumberChange(event.target.value));
    };

    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        if (target.value === '0') {
            target.value = '';
        }
    };

    useEffect(() => {
        const result: ScalingConfig = {minCapacity: selectedMin,
            maxCapacity: selectedMax, desiredCount: desiredCount};
            onChange(result);
    }, [selectedMin, selectedMax, desiredCount, onChange]);

    return (
        <Box>
                    <FormControl fullWidth>
                    <InputLabel id="loadbalancer-scaling-label">Scaling properties for ECS deployment</InputLabel>
                    <TextField
                        label="Min Capacity"
                        id="scaling-min-field"
                        value={selectedMin}
                        onChange={handleMinChange}
                        type="number"
                        onClick={handleClick}
                     />
                    <TextField
                        label="Max Capacity"
                        id="scaling-max-field"
                        value={selectedMax}
                        onChange={handleMaxChange}
                        type="number"
                        onClick={handleClick}
                     />
                    <TextField
                        label="Desired Count"
                        id="scaling-desired-field"
                        value={desiredCount}
                        onChange={handleDesiredCountChange}
                        type="number"
                        onClick={handleClick}
                        disabled
                    />
                    </FormControl>
        </Box>
    );
};